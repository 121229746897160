.scrollButton {
  position: fixed;  
   width: 100%; 
   left: 95%; 
   bottom: 40px; 
   height: 20px; 
   font-size: 3rem; 
   z-index: 1; 
   cursor: pointer; 
   color: green; 
}


@media (max-width: 900px) {
  .scrollButton {
    display: none
  }
}