.newsCardContainer {
  width: 20%;
  height: 25rem;
  padding: 1rem;
  box-shadow: var(--shadow);
  border-radius: 0.2rem;
  justify-content: flex-start;
  gap: 1rem;
  position: relative;
  cursor: pointer;
}

.newsCardContainer {
  text-align: start;
}

.newsCardContainer > img {
  width: 100%;
  height: 50%;
  object-fit: contain;
}

.newsCardContainer > h1 {
  color: var(--primary);
  height: 11%;
  overflow: hidden;
}

.newsCardDes {
  height: 25%;
  font-size: 0.8rem;
  text-align: justify;
  overflow: hidden;
}

.newsCardCreateDate {
  width: 100%;
  position: absolute;
  bottom: 4%;
  left: 5%;
  font-size: 0.7rem;
  text-align: justify;
  justify-content: flex-start;
  color: gray;
}

@media (max-width:600px) {
  .newsCardContainer {
    width: 93%;
  }
}