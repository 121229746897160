@font-face {
  font-family: 'MicrosoftPhagsPa-Bold';
  src: local('MicrosoftPhagsPa-Bold'), url('/public/assets/fonts/MicrosoftPhagsPaBold.ttf') format("truetype");
}
@font-face {
  font-family: 'MicrosoftPhagsPa';
  src: local('MicrosoftPhagsPa'), url('/public/assets/fonts/MicrosoftPhagsPaRegular.ttf') format("truetype")
}

:root {
  --primary: #12519C;
  --secondary: #FFD466;
  --background: #F6F6F6;
  --ligthBackground: #F0F0F0;
  --hotaction: rgba(141, 13, 13);
  --black: #000;
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --grey-gradient: linear-gradient(180deg, #d9d9d900 20%, #D9D9D9 100%);
  --blue: #4066ff;
  --lightBlue: #eeeeff;
  --shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  --re-shadow: 0px -10px 50px -5px rgba(0, 0, 0, 0.25);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  font-family: 'MicrosoftPhagsPa';
  font-size: 1rem;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}
.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.innerHeight {
  height: 100vh;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.boldText {
  font-family: 'MicrosoftPhagsPa-Bold';
}

.primaryText {
  color: var(--primary);
  font-weight:
   bold;
  font-size: 2rem;
}

.secondaryText {
  color: rgb(140 139 139);
  font-size: 0.9rem;
}
.orangeText {
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}

/* media queries */

@media (max-width: 1280px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 5rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
}