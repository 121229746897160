
.careerCover {
  width: 100%;
}

.careerCover > img {
  width: 100%;
}

.careersContainer {
  gap: 3rem;
}

.careerInfo {
  padding: 0 2rem;
  gap: 2rem;
  align-items: flex-start;
}

.careerWhyUs {
  width: 45%;
  gap: 1rem;
  text-align: justify;
  line-height: 2rem;
}

.careerWhyUs > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.careerCulture {
  width: 45%;
  gap: 1rem;
  text-align: justify;
  line-height: 2rem;
}

.careerCulture > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.careersAvail {
  padding: 0 2rem;
  gap: 2rem;
  align-items: flex-start;
}

.careerTitle {
  width: 45%;
  gap: 0.5rem;
}

.careerTitle > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.careersDes {
  width: 45%;
  gap: 1rem;
  text-align: justify;
  line-height: 2rem;
}

.careersDes > button {
  padding: 0.7rem 1rem;
  border: none;
  border-radius: 0.2rem;
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

.careersData {
  width: 100%;
}

.careersNoJob {
  width: 100%;
  padding: 0 4rem;
}

@media (max-width: 600px) {
  .careerInfo {
    padding: 0;
  }

  .careerWhyUs {
    width: 100%;
    padding: 0 2rem;
  }

  .careerCulture {
    width: 100%;
    padding: 0 2rem;
  }

  .careersAvail {
    padding: 0;
  }

  .careerTitle {
    width: 100%;
    padding: 0 2rem;
    text-align: justify;
    justify-content: flex-start;
  }

  .careersDes {
    width: 100%;
    padding: 0 2rem;
  }

}