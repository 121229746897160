.aboutHomePageWrapper {
  width: 95%;
}

.aboutHomePageContainer {
  align-items: flex-start;
  gap: 2rem;
}

.aboutHomePageDetail {
  width: 35%;
  padding: 1rem;
  text-align: justify;
  gap: 1rem;
  justify-content: flex-start;
}

.aboutHomePageDetail > h1 {
  font-size: 1.5rem;
  color: var(--primary)
}

.vl {
  border-left: 2px solid var(--primary);
  height: 15rem;
}

.aboutHomePageWB {
  width: 45%;
  padding: 1rem;
  text-align: justify;
  gap: 1rem;
  align-items: flex-start;
}

.aboutHomePageWorld > ul {
  list-style-type: none;
}

.aboutHomePageBridge > ul {
  list-style-type: none;
}

.aboutHomePageWorld {
  width: 45%;
  gap: 1rem;
}

.aboutHomePageWorld > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.aboutHomePageBridge {
  width: 40%;
  padding-left: 1rem;
  gap: 1rem;
}

.aboutHomePageBridge > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.aboutHomePageul {
  gap: 0.5rem;
}

@media (max-width : 899px) {
  .aboutHomePageDetail {
    width: 100%;
  }

  .aboutHomePageWB {
    width: 100%;
  }

  .aboutHomePageWorld {
    width: 100%;
    padding-left: 1rem;
  }

  .vl {
    display: none;
  }

  .aboutHomePageBridge {
    width: 100%;
    padding: 0;
    margin-top: 1rem;
  }
}

@media (min-width: 900px) and (max-width: 1630px) {
  .aboutHomePageDetail {
    width: 80%;
  }

  .aboutHomePageWB {
    width: 100%;
  }
  .aboutHomePageWorld {
    width: 20rem;
  }
  
  .aboutHomePageBridge {
    width: 20rem;
  }

}