.businessWrapper {
  width: 80%;
}

/* .businessContainer {
  width: 80%;
} */

.businessContainer > h1 {
  font-size: 1.5rem;
  color: var(--primary)
}

.businessIcons {
  gap: 5rem;
}

.businessEachelement {
  gap: 1rem;
}

.businessImage {
  padding: 1rem 2.5rem;
  gap: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .businessIcons {
    gap: 1rem;
  }
}