.certificatesWrapper {
  width: 100%;
}

.certificatesContainer {
  width: 100%;
  padding: 5rem;
}

.certificatesTitle {
  width: 100%;
  padding: 0 10rem;
}

.certificatesTitle > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.certificatesTitle .hl {
  width: 100%;
}

.certificatesDetail {
  width: 100%;
  padding: 0 10rem;
}

.certificatesInfo {
  width: 100%;
  gap: 2rem;
}

.certImage {
  width: 45%;
}

.certImage > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.certDes {
  width: 50%;
  padding: 2rem;
  text-align: justify;
  line-height: 2rem;
}

@media (max-width:600px) {
  .certificatesContainer {
    padding: 2rem 0 2rem 0;
  }
  .certificatesTitle {
    padding: 0 2rem;
  }
  .certificatesDetail{
    padding: 0 2rem;
  }
  .certDes {
    width: 90%;
    padding: 0;
  }
}