
.contactusContainer {
  gap: 2rem;
}

.contactusInfor > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.contactusInfor {
  width: 50%;
  gap: 1rem;
  height: 45vh;
  padding: 0 2rem;
  justify-content: flex-start;
}

.contactusElements {
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}


.contactusButton {
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  color: white;
  background-color: var(--primary);
}

.contactusMap {
  width: 45%;
}

@media (max-width: 600px) {

  .contactusInfor {
    width: 100%;
  }

  .contactusMap {
    width: 100%;
    margin-top: 5rem;
  }

  .contactusMap > iframe {
    width: 320px;
    height: 200px;
  }
}