.footerWrapper {
  box-shadow: var(--re-shadow);
  padding-bottom: 2rem;
}

.footerContainer {
  padding: 1rem;
  gap: 2rem;
  align-items: flex-start;
}

.footer-left{
  width: 20%;
  gap: 1rem;
}

.footer-left > button {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem; 
  border: none;
  background-color: var(--primary);
  color: white;
  border-radius: 0.2rem;
}

.footer-center {
  width: 25%;
  padding: 1rem 2rem;
  gap: 1rem;

}

.footerCenterElements {
  width: 100%;
  gap: 2rem;
}

.footerCenterDetail {
  width: 80%;
  text-wrap: wrap;
  font-size: 0.9rem;
  text-align: justify;
}

.footer-right {
  width: 20%;
  padding-top: 1rem;
  gap: 1rem;
}

.footerRightMedia {
  gap: 1rem;
}

.footerRightMedia > a {
  cursor: pointer;
}

@media (max-width: 600px) {
  .footer-left {
    width: 100%;
  }

  .footer-center {
    width: 100%;
  }

  .footer-right {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .footer-left {
    width: 80%;
  }

  .footer-center {
    width: 80%;
  }

  .footer-right {
    width: 80%;
  }
}

@media (min-width: 901px) and (max-width: 1260px) {
  .footer-left {
    width: 25%;
  }

  .footer-center {
    width: 32%;
  }

  .footer-right {
    width: 20%;
  }
}