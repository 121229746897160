.newsPageContainer {
  padding: 2rem;
}

.newsPageTitle {
  width: 100%;
  padding: 0 7rem;
}

.newsPageTitle .hl {
  width: 100%;
}

.newsPageTitle > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.newsPagesInfo {
  width: 100%;
  gap: 1rem;
  padding: 0 0rem;
}

@media (max-width: 600px) {
  .newsPageContainer {
    padding-bottom: 5rem;
  }

  .newsPageTitle {
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 1580px) {
  .newsPageTitle {
    width: 100%;
    padding: 0 17rem;
  }

  .newsPagesInfo {
    padding: 0 10rem;
  }
}