.categoryWrapper {
  width: 100%;
  padding-bottom: 5rem;
}
.categoryContainer {
  width: 100%;
}

.categoryTitle {
  width: 30%;
  
}

.categoryTitle > h1 {
  font-size: 1.1rem;
  transform: rotate(-90deg)
}

.categoryDetail {
  width: 70%;
  gap: 1.5rem;
}

.categoryText {
  text-align: justify;
}

.categoryLogo {
  width: 100%;
  gap: 3rem;
  flex-wrap: wrap;
}

.categoryDetail .hl {
  width: 100%;
}

.categoryLogoImg {
  width: 15%;
}

.categoryLogoImg > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 600px) {
  .categoryWrapper {
    padding: 0 0 3rem 0;
  }

  .categoryTitle {
    width: 100%; 
  }
  .categoryTitle > h1 {
    font-size: 1.1rem;
    transform: rotate(0deg)
  }

  .categoryLogo {
    gap: 1rem;
  }

  .categoryLogoImg {
    width: 45%;
  }
}