.mvWrapper {
  width: 80%;
}

.mvContainer {
  width: 100%;
}

.mvVision {
  width: 45%;
  padding: 3rem;
  gap: 1rem;
  text-align: justify;
}

.mvMission {
  width: 45%;
  padding: 3rem;
  gap: 1rem;
  text-align: justify;
}

.mvTitle {
  font-size: 1.5rem;
  color: var(--primary);
}

@media (max-width : 899px) {
  .mvVision {
    width: 100%;
    padding: 1rem;
  }
  .mvMission {
    width: 100%;
    padding: 1rem;
  }
}

@media (min-width: 900px) and (max-width: 1230px) {
  .mvVision {
    width: 50%;
    padding: 1rem;
  }
  .mvMission {
    width: 50%;
    padding: 1rem;
  }
}