.aboutusCover {
  width: 100%;
  object-fit: contain;
}

.aboutusCover > img {
  width: 100%;
  object-fit: contain;
}

.aboutusContainer {
  width: 100%;
  gap: 2rem;
  padding: 2rem;
}

.aboutUsDetail {
  align-items: flex-start;
}

.aboutusTitle {
  width: 30%;
  padding-left: 10%;
  /* gap: 0.5rem; */
}

.hl {
  width: 80%;
  height: 1rem;
  border-bottom: 2px solid black;
}

.aboutUsTitleText {
  color: var(--primary);
  font-size: 1.5rem;
}

.aboutUsInfo {
  width: 70%;
  text-align: justify;
  padding: 0 3rem;
  line-height: 2rem;
}

.aboutUsChairmanDetail {
  gap: 1rem;
}

.aboutUsChairman {
  width: 100%;
  text-align: justify;
  gap: 2rem;
  align-items: flex-start;
}

.chairmanPhoto {
  width: 25%;
  padding-left: 6%;
}

.chairmanPhoto > img {
  width: 100%;
}

.chairmanWords {
  width: 65%;
  line-height: 2rem;
}

.aboutUsMilestone {
  width: 100%;
}

.milestoneImage {
  width: 100%;
}

.milestoneImage > img {
  object-fit: contain;
}

@media (max-width: 899px) {
  .aboutusTitle {
    width: 100%;
  }
  .aboutUsInfo {
    width: 100%;
    padding: 0 2rem;
  }

  .chairmanPhoto {
    width: 95%;
    padding: 0 1.5rem;
  }

  .chairmanWords {
    width: 100%;
    padding: 0 1.5rem;
  }
   
  .aboutUsMilestone {
    gap: 2rem;
  }

  .milestoneImage > img {
    width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 900px) and (max-width: 1480px) {
  .milestoneImage > img {
    width: 100%;
    object-fit: contain;
  }
}