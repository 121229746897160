.milestoneContianer {
  position: relative;
}

.titleBox {
  position: absolute;
  top: 0;
  left: 25rem;
  font-size: 2rem;
  font-weight: bold;
  transform: rotate(-30deg);
}

.contentBox {
  width: 25rem;
  height: 15rem;
  background-color: var(--primary);
  border-radius: 1rem;
  color: white;
  padding: 1rem;
}