
.headerWrapper {
  width: 100%;
  top: 0;
  box-shadow: var(--re-shadow);
  position: relative;
  z-index: 999;
}

.headerContainer {
  gap: 6rem;
  width: 100%;
}

.h-logo {
  width: 20%;
  padding: 1rem;
}

.header-logo {
  width: 10rem;
}

.OutsideClickBox {
  width: 100%;
}

.h-menu {
  width: 100%;
  gap: 5rem;
}

.menuBar {
  display: none;
}  

@media (max-width: 899px) {
  .menuBar {
    display: block;
  }

  .headerContainer {
    gap: 3rem;
    justify-content: space-between;
    padding: 0 1.5rem
  }

  .header-logo {
    width: 8rem;
  }

  .h-menu {
    width: 50%;
    height: 100vh;
    color: var(--black);
    position: absolute;
    gap: 3rem;
    flex-direction: column;
    right: 0;
    top: 0;
    background: white;
    display: flex;
    transition: all 450ms ease;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
}

@media (min-width: 900px) and (max-width: 1320px) {
  .headerContainer {
    gap: 1rem;
    width: 100%;
    padding: 0 2rem;
  }

  .header-logo {
    width: 8rem;
  }

  .h-menu {
    width: 100%;
    gap: 2rem;
  }

  .h-menu > a {
    font-size: 0.9rem;
  }
}