.companiesWrapper {
  width: 100%;
  gap: 2rem;
}

.companiesCover {
  width: 100%;
}

.companiesCover > img {
  width: 100%;
  object-fit: cover;
}

.companiesContainer {
  width: 100%;
  padding: 1rem 3rem;
  gap: 2rem;
}

.companiesTitle {
  width: 50%;
  padding-left: 10%;
}

.companiesTitle > h1 {
  font-size: 1.5rem;
  color: var(--primary);
}

.companiesCategories {
  width: 100%;
  gap: 5rem;
}

@media (max-width:600px) {
  .companiesContainer{
    padding: 0;
  }
}