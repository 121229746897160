.newsDetailContainer {
  width: 70%;
  padding: 5rem 10rem;
}

.newsDetailImage {
  width: 100%;
}

.newsDetailImage > img {
  width: 100%;
}

.newsDetailDate {
  width: 100%;
}

.newsDetailInfo {
  width: 100%;
}

.newsDetailInfo > h1 {
  font-size: 1.5rem;
  color: var(--primary)
}

.newsDetailInfo .hl {
  width: 100%;
}

.newsDetailDes {
  padding-top: 1.5rem;
  text-align: justify;
  line-height: 2rem;
}

.newsDetailPara {
  gap: 1rem;
}

.newsImages {
  padding-top: 2rem;
  width: 100%;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.newsImageList {
  width: 100%;
}

.newsImageList > img {
  width: 100%;
  object-fit: contain;
  border-radius: 0.3rem;
}

@media (max-width : 600px) {
  .newsDetailContainer {
    padding: 2rem 0 2rem 0;
  }
  .newsDetailInfo > h1 {
    text-align: start;
    font-size: 1.3rem;
  }
  .newsImageList {
    width: 100%;
  }
}