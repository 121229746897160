.newsWrapper {
  width: 80%;
}

.divider {
  margin: 2rem 0 5rem 0;
  border-top: 2px solid var(--primary);
  /* height: 2px; */
}

.newsContainer {
  width: 100%;
  margin-bottom: 10%;
  gap: 3rem;
}

.newsTitle {
  width: 100%;
}

.newsTitle > h1 {
  font-size: 1.5rem;
  color: var(--primary)
}

.newsCardElement {
  width: 100%;
  gap: 2rem;
}


.newsCardElement .newsCardContainer {
  width: 92%;
}
.newsContainer .swiper-wrapper {
  padding: 1rem;
}

@media (max-width:600px) {
  .newsCardElement {
    width: 100%;
  }
}